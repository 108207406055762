
import {defineComponent, onMounted, reactive, toRefs, computed, ref, UnwrapRef, toRaw} from "vue";
import router from "@/router";
import StorageUtil from "@/utils/storage_util";
import PageUtil from "@/utils/page_util";
import {dateFormat} from "@/utils/common";
import {message} from "ant-design-vue";
import ApiExamination from "@/request/apis/api_examination";

interface IState {
  list: any[],
  total: number;
  loading: boolean;
  searches: any
  visible: boolean
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '试卷名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '唯一标识',
    dataIndex: 'tag',
    key: 'tag',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
  },
  {
    title: '更新时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '操作',
    key: 'control',
  },
];

export default defineComponent({
  name: 'AppVersionList',
  setup() {
    const fileUploadRef = ref()
    const formRef = ref()
    const rules = {
      name: [{required: true, message: "请输入试卷名称"},],
      tag: [{required: true, message: "请输入唯一标识，建议用英文"},],
    }

    let formState: any = reactive({
      "name": "",//试卷名称
      "sort": 0,//排序 越小越靠前 可负数
      "pid": 0,//上级试卷id 默认0
      "status": 1,//状态 0禁用 1启用
      "tag": "",//唯一标识
    })

    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      visible: false,
      searches: {
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize,
      },
    })

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiExamination.getPaperList(params);
      state.loading = false
      if (res && res.error_code === 0) {
        const data: any = res.data || []
        state.total = data.count
        state.list = data.list
      }
    }

    // 设置modal显示隐藏
    const setVisible = (flg: boolean, item?: any) => {
      state.visible = flg
      if (flg && item) {
        formState.name = item?.name || ''
        formState.sort = item?.sort || 0
        formState.status = item?.status || 1
        formState.tag = item?.tag || ''
        formState.id = item?.id || ''
      }
      if (!flg) {
        setTimeout(() => {
          formRef.value.resetFields()
          fileUploadRef.value.reset()
        }, 300)
      }
    }

    // 提交
    const onSubmit = () => {
      formRef.value
          .validate()
          .then(async () => {
            let params = {...toRaw(formState)}
            state.loading = true
            const res = await ApiExamination.setPaper(params);
            state.loading = false
            if (res && res.error_code == 0) {
              message.success(params.id ? '编辑成功' : '新增成功')
              state.searches.page = 1
              setVisible(false)
              await getList();
            } else {
              message.error(res?.msg || '操作失败，请重试');
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }

    const onDel = async (item: any) => {
      const res = await ApiExamination.delPaper(item.id);
      if (res && res.error_code == 0) {
        message.success('删除成功')
        await getList();
      } else {
        message.error(res?.msg || '删除版本失败，请重试');
      }
    }

    const goDetail = (item: any) => {
      router.push({
        path: '/examination/questions/'+item.id,
      })
    }


    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    // 初始化search参数
    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      handleTableChange,
      onSearch,
      resetSearch,
      onSubmit,
      setVisible,
      dateFormat,
      onDel,
      goDetail,
      fileUploadRef,
      rules,
      formRef,
      formState,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
    }
  }
})
