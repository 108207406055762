import {baseRequestClient} from "@/request/request_client";
import {IList, IListResult} from "@/models/base_model";
import {IDictionaryItem, IDictionaryUpdate} from "@/models/setting_model";
import {IMenuItem, IMenuUpdate} from "@/models/menu_model";
import {IAppVersionItem} from './../../models/version_model';

const requestClient = baseRequestClient

// 页面配置
export default class ApiExamination {

    // GET 跳转试卷列表 page&limit
    static getPaperList = async (params) => {
        return await requestClient.get<any[]>('/admin/qa.paper/list', params)
    }

    // 新增/更新试卷
    static setPaper = async (data) => {
        return await requestClient.post('/admin/qa.paper/store', data)
    }

    // POST
    static delPaper = async (id) => {
        return await requestClient.post('/admin/qa.paper/destroy', {id})
    }

    // GET 跳转配置详情
    static getPaperDetail = async (id: string) => {
        return await requestClient.get<any>('/admin/qa.paper/info', {id})
    }

    // GET 题目列表 page&limit
    static getQuestionList = async (params) => {
        return await requestClient.get<any[]>('/admin/qa.question/index', params)
    }

    // 新增/更新试卷
    static addQuestion = async (data) => {
        return await requestClient.post('/admin/qa.question/store', data)
    }

    // POST
    static updateQuestion = async (data) => {
        return await requestClient.post('/admin/qa.question/update', data)
    }

    // GET 跳转配置详情
    static delQuestion = async (id: any) => {
        return await requestClient.post('/admin/qa.question/destroy', {id})
    }

}
